import { Button, Divider, Typography, makeStyles } from "@material-ui/core";
import {
  EditButton,
  Pagination,
  ReferenceManyField,
  TopToolbar,
  useTranslate,
} from "react-admin";

import ContentAdd from "@material-ui/icons/Add";
import DeleteWithConfirmButton from "../buttons/DeleteWithConfirmButton";
import { Link } from "react-router-dom";
import React from "react";
import ResponsiveList from "../../components/lists/ResponsiveList";
import { get } from "lodash";
import { stringify } from "query-string";
import { useListContentStyles } from "../../components/lists/useStyles";

const _makeRedirect = ({ resource, record, tab }) => {
  if (tab > 0) {
    return `/${resource}/${get(record, "id")}/${tab}`;
  }
  return `/${resource}/${get(record, "id")}`;
};

export const makeRedirect = (props) => _makeRedirect(props);

const PaginationWrapper = ({ fullWidth, addLabel, ...props }) => (
  <Pagination {...props} />
);

const useStyles = makeStyles((theme) => ({
  toolbar: {},
  label: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  smartButton: {
    [theme.breakpoints.down("md")]: {
      padding: 0,

      "& .MuiButton-label": {
        padding: 0,
      },
      "& .MuiButton-label > span": {
        display: "none",
        visibility: "hidden",
        padding: 0,
      },
    },
  },
}));

const ListField = ({
  removeRedirect,
  helperText = null,
  reference,
  target,
  fullWidth,
  expand,
  tab = 0,
  source,
  mobileHeader,
  mobileBody,
  mobileActions,
  filter,
  canCreate = true,
  canModify = true,
  canRemove = true,
  additionalData,
  ...props
}) => {
  const { resource, record } = props;
  const translate = useTranslate();
  const classes = useListContentStyles();
  const customClasses = useStyles();

  const { label } = props;
  if (!removeRedirect) {
    removeRedirect = makeRedirect({ resource, record, tab });
  }
  return (
    <div className={classes.root}>
      <Typography variant="subtitle1" className={customClasses.label}>
        {translate(label || `resources.${resource}.fields.${source}`)}
      </Typography>
      <Divider />
      {helperText !== null && (
        <Typography
          component="div"
          variant="body1"
          dangerouslySetInnerHTML={{ __html: translate(helperText) }}
        />
      )}
      <ReferenceManyField
        {...props}
        reference={reference}
        target={target}
        filter={{ [target]: get(record, "id", 0), ...filter }}
        pagination={<PaginationWrapper />}
      >
        <ResponsiveList
          classes={{
            ...classes,
            root: undefined,
          }}
          expand={expand}
          mobileHeader={mobileHeader}
          mobileBody={mobileBody}
          mobileActions={mobileActions}
          empty={translate(`resources.${reference}.messages.empty`)}
        >
          {React.Children.map(props.children, (field, index) =>
            React.isValidElement(field)
              ? React.cloneElement(field, { key: index })
              : null
          )}
          {canModify && <EditButton />}
          {canRemove && <DeleteWithConfirmButton redirect={removeRedirect} />}
        </ResponsiveList>
      </ReferenceManyField>
      {canCreate && (
        <TopToolbar resource={resource} className={customClasses.toolbar}>
          <Button
            startIcon={<ContentAdd />}
            component={Link}
            disableElevation
            variant="outlined"
            color="primary"
            to={{
              pathname: `/${reference}/create`,
              search: stringify({
                source: JSON.stringify({
                  [target]: get(record, "id"),
                  ...additionalData,
                }),
              }),
            }}
          >
            {translate(`resources.${resource}.buttons.add_${source}`)}
          </Button>
        </TopToolbar>
      )}
    </div>
  );
};

export default ListField;
