import { ArrayInput, SimpleFormIterator } from "ra-ui-materialui";

import React from "react";
import TextInput from "../../components/inputs/TextInput";

const PhotoScoutingLocationsInput = (props) => (
  <ArrayInput {...props}>
    <SimpleFormIterator>
      <TextInput source="name" maxLength={100} fullWidth />
    </SimpleFormIterator>
  </ArrayInput>
);
export default PhotoScoutingLocationsInput;
