import {
  DateField,
  List as RaList,
  TextField,
  usePermissions,
} from "react-admin";
import React, { Fragment } from "react";
import { useListStyles, useSelector } from "../components/lists/useStyles";

import AccountInfoField from "./fields/AccountInfoField";
import Filters from "./Filters";
import HelpProvider from "../components/HelpProvider";
import LongTextField from "../components/fields/LongTextField";
import ResponsiveList from "../components/lists/ResponsiveList";
import Workflow from "../components/workflow";
import perPage from "../utils/perPage";
import useFieldLabel from "../components/useFieldLabel";

const List = (props) => {
  const { loaded, permissions } = usePermissions();
  const sidebarOpen = useSelector((state) => state.admin.ui.sidebarOpen);
  const classes = useListStyles({ sidebarOpen });
  const isAdmin = loaded && permissions(["admin"]);
  const fieldLabel = useFieldLabel({ ...props });

  return (
    <Fragment>
      <HelpProvider />
      <RaList
        {...props}
        classes={classes}
        perPage={perPage(25, 25)}
        filters={<Filters />}
        exporter={false}
        sort={{ field: "id", order: "DESC" }}
        bulkActionButtons={false}
      >
        <ResponsiveList>
          <TextField source="id" />
          <LongTextField source="company.name" sortBy="Companies.name" />
          <AccountInfoField label={fieldLabel("account")} sortable={false} />
          <Workflow.StateCollectionInput
            source="state"
            readonly={!isAdmin}
            admin={isAdmin}
          />
          <DateField source="modified" showTime />
          <Workflow.EditButton />
        </ResponsiveList>
      </RaList>
    </Fragment>
  );
};

export default List;
