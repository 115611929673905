import {
  DateField,
  EditButton,
  EmailField,
  List as RaList,
  TextField,
  useTranslate,
} from "react-admin";
import { useListStyles, useSelector } from "../components/lists/useStyles";

import CompaniesField from "./fields/CompaniesField";
import Filters from "./Filters";
import LoginButton from "./buttons/LoginButton";
import React from "react";
import ResponsiveList from "../components/lists/ResponsiveList";
import StatusField from "./fields/StatusField";
import perPage from "../utils/perPage";

const Datagrid = ({ ...props }) => {
  const translate = useTranslate();
  return (
    <ResponsiveList
      {...props}
      mobileHeader={
        <React.Fragment>
          <TextField
            source="name"
            label="resources.users.fields.profile.name"
            style={{ fontWeight: "bold" }}
            variant="subtitle1"
          />
          <EditButton />
        </React.Fragment>
      }
      mobileBody={
        <React.Fragment>
          <DateField source="created" showTime color="textSecondary" />
          <DateField
            source="last_login"
            showTime
            color="textSecondary"
            emptyText={translate("ra.page.empty")}
          />
          <StatusField source="status" />
        </React.Fragment>
      }
      mobileActions={
        <React.Fragment>
          <LoginButton />
        </React.Fragment>
      }
    >
      <TextField source="id" label="ID" style={{ fontWeight: "bold" }} />
      <TextField
        source="profile.name"
        sortBy="UserProfiles.name"
        variant="body2"
      />
      <TextField
        source="profile.surname"
        sortBy="UserProfiles.surname"
        variant="body2"
      />
      <EmailField source="email" />
      <CompaniesField source="companies" sortBy="Companies.name" />
      <DateField source="last_login" showTime />
      <StatusField source="status" />
      <LoginButton />
      <EditButton />
    </ResponsiveList>
  );
};

const List = (props) => {
  const sidebarOpen = useSelector((state) => state.admin.ui.sidebarOpen);
  const classes = useListStyles({ sidebarOpen });
  return (
    <RaList
      {...props}
      classes={classes}
      perPage={perPage(25, 25)}
      filters={<Filters />}
      exporter={false}
      sort={{ field: "created", order: "DESC" }}
    >
      <Datagrid {...props} />
    </RaList>
  );
};

export default List;
