import React, { useMemo } from "react";
import { ReferenceInput, SelectInput } from "ra-ui-materialui";

import { get } from "lodash";
import { useFormState } from "react-final-form";

const TicketTypeInput = (props) => {
  const formState = useFormState();
  const companyId = useMemo(
    () => get(formState.values, "company_id"),
    [formState.values]
  );
  return (
    <ReferenceInput
      {...props}
      reference="ticket-types"
      filter={{ company_id: companyId }}
      sort={{ field: "order_index", order: "ASC" }}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
  );
};
export default TicketTypeInput;
