import { Chip, makeStyles } from "@material-ui/core";

import React from "react";
import { get } from "lodash";

const useStyles = makeStyles((theme) => ({
  chip: {
    marginRight: theme.spacing(1),
  },
  p: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "20rem",
  },
  sub: {
    marginTop: -theme.spacing(0.5),
    fontSize: 10,
    padding: 0,
    margin: 0,
  },
}));
const CompaniesField = ({ source, record }) => {
  const classes = useStyles();
  return get(record, source, []).map((company) => (
    <Chip
      component="div"
      className={classes.chip}
      key={get(company, "id")}
      label={
        <section>
          <div className={classes.p}>{get(company, "company.name")}</div>
          {get(company, "role.name", null) !== null && (
            <div className={classes.sub}>{get(company, "role.name")}</div>
          )}
        </section>
      }
      color="primary"
    />
  ));
};
export default CompaniesField;
