import { ArrayInput, SimpleFormIterator } from "ra-ui-materialui";

import React from "react";
import TextInput from "../../components/inputs/TextInput";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  input: {
    float: "left",
    marginRight: theme.spacing(2),
  },
  inputFullname: {
    minWidth: 300,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
}));
const LocationScoutingDelegatesInput = ({ fullWidth, ...props }) => {
  const classes = useStyles();
  return (
    <ArrayInput {...props}>
      <SimpleFormIterator>
        <TextInput
          className={classnames(classes.input, classes.inputFullname)}
          source="full_name"
          maxLength={100}
        />
        <TextInput className={classes.input} source="role" maxLength={50} />
      </SimpleFormIterator>
    </ArrayInput>
  );
};
export default LocationScoutingDelegatesInput;
