import {
  BooleanField,
  ChipField,
  DateField,
  EditButton,
  List as RaList,
  TextField,
} from "react-admin";

import Filters from "./Filters";
import React from "react";
import ResponsiveList from "../components/lists/ResponsiveList";
import perPage from "../utils/perPage";
import { useListStyles } from "../components/lists/useStyles";
import { useTheme } from "@material-ui/core";

const List = (props) => {
  const classes = useListStyles();
  const theme = useTheme();
  return (
    <RaList
      {...props}
      classes={classes}
      filters={<Filters />}
      exporter={false}
      perPage={perPage(25, 25)}
      sort={{ field: "order", order: "asc" }}
    >
      <ResponsiveList
        mobileHeader={
          <React.Fragment>
            <TextField
              source="name"
              variant="subtitle1"
              style={{ fontWeight: "bold" }}
            />
            <EditButton />
          </React.Fragment>
        }
        mobileBody={
          <React.Fragment>
            <TextField source="code" variant="overline" />
            <BooleanField source="is_menu" />
            <DateField source="modified" showTime color="textSecondary" />
          </React.Fragment>
        }
        mobileActions={
          <React.Fragment>
            <TextField
              source="language.name"
              label="resources.pages.fields.language_id"
              variant="overline"
              style={{ fontWeight: "bold", padding: theme.spacing(2) }}
            />
          </React.Fragment>
        }
      >
        <TextField source="id" label="ID" />
        <TextField
          source="language.name"
          label="resources.pages.fields.language_id"
        />
        <TextField source="name" style={{ fontWeight: "bold" }} />
        <ChipField source="code" style={{ marginLeft: 0 }} />
        <TextField source="order" />
        <BooleanField source="is_menu" />
        <DateField source="modified" showTime />
        <EditButton />
      </ResponsiveList>
    </RaList>
  );
};
export default List;
