import { Button, Menu, MenuItem } from "@material-ui/core";
import React, { useMemo, useState } from "react";

import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ConfirmMove from "../form/ConfirmMove";
import StateField from "../fields/StateField";
import { get } from "lodash";
import { useRoles } from "../../../authProvider";
import useWorkflow from "../useWorkflow";

const StateCollectionInput = ({
  readonly = false,
  admin = false,
  record,
  resource: toResolve,
}) => {
  const [state, setState] = useState(null);
  const roles = useRoles();
  // Se il componente è utilizzato all'interno di TransactionLogsField
  // è necessario risolvere il vero nome della risorsa partendo dalla risorsa
  // utilizzata per recuperare l'elenco dei logs.
  const resource = useMemo(
    () => toResolve.replace("workflow/transactions/", ""),
    [toResolve]
  );
  const workflow = useWorkflow({ resource });
  const { currentState, nextStates } = useMemo(() => {
    const currentState =
      (workflow &&
        workflow.getState({
          ...record,
          // Se il componente è utilizzato in TransactionLogsField la transazione equivale
          // al record stesso, quindi provvedo ad associarla automaticamente per consentire
          // comunque la risoluzione dello stato ad essa associato.
          transaction: get(record, "transaction", record),
        })) ||
      undefined;
    const nextStates = workflow.getNextStates(roles, record);
    return { currentState, nextStates };
  }, [roles, record, workflow]);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setState(null);
  };

  const handleChange = (state) => {
    setAnchorEl(null);
    setState(state);
  };
  if (readonly || nextStates.length === 0) {
    // Restituisco il classico ChipField che mostra lo stato corrente.
    return <StateField record={record} resource={toResolve} />;
  }

  return (
    <div>
      <Button
        disableElevation
        endIcon={<ArrowDropDownIcon />}
        color="primary"
        variant="contained"
        aria-controls="simple-menu"
        aria-haspopup="true"
        size="small"
        onClick={handleClick}
      >
        {get(currentState, "name")}
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {nextStates.map((state) => (
          <MenuItem
            key={get(state, "code")}
            onClick={handleChange.bind(this, state)}
          >
            {get(state, "label")}
          </MenuItem>
        ))}
      </Menu>
      <ConfirmMove
        admin={admin}
        open={state !== null}
        resource={resource}
        record={record}
        state={state}
        onCancel={handleClose}
      />
    </div>
  );
};
export default StateCollectionInput;
