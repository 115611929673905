import AutocompleteInput from "./AutocompleteInput";
import React from "react";
import { ReferenceInput } from "react-admin";

const UserInput = (props) => (
  <ReferenceInput
    {...props}
    reference="users"
    sort={{ field: "username", order: "ASC" }}
  >
    <AutocompleteInput optionText="name" />
  </ReferenceInput>
);
export default UserInput;
