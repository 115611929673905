import {
  CloneButton,
  EditButton,
  List as RaList,
  TextField,
} from "react-admin";

import Filters from "./Filters";
import React from "react";
import ResponsiveList from "../components/lists/ResponsiveList";
import TextFieldInput from "../components/fields/TextFieldInput";
import perPage from "../utils/perPage";
import { useListStyles } from "../components/lists/useStyles";
import { useTheme } from "@material-ui/core";

const List = (props) => {
  const classes = useListStyles();
  const theme = useTheme();
  return (
    <RaList
      {...props}
      classes={classes}
      filters={<Filters />}
      exporter={false}
      perPage={perPage(25, 25)}
      sort={{ field: "id", order: "DESC" }}
    >
      <ResponsiveList
        mobileHeader={
          <React.Fragment>
            <TextField
              source="code"
              variant="subtitle1"
              style={{ fontWeight: "bold" }}
            />
            <EditButton />
          </React.Fragment>
        }
        mobileBody={
          <React.Fragment>
            <TextField source="text" color="textSecondary" />
          </React.Fragment>
        }
        mobileActions={
          <React.Fragment>
            <TextField
              source="language.name"
              label="resources.language-messages.fields.language_id"
              variant="overline"
              style={{ fontWeight: "bold", padding: theme.spacing(2) }}
            />
          </React.Fragment>
        }
      >
        <TextField source="id" label="ID" />
        <TextField
          source="language.name"
          label="resources.language-messages.fields.language_id"
        />
        <TextField source="code" style={{ fontWeight: "bold" }} />
        <TextFieldInput source="text" color="textSecondary" />
        <EditButton />
        <CloneButton />
      </ResponsiveList>
    </RaList>
  );
};
export default List;
