import { Filter, SearchInput } from "react-admin";

import React from "react";
import StateInput from "../components/workflow/inputs/StateInput";

const Filters = (props) => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn fullWidth />
    <StateInput source="state" />
  </Filter>
);
export default Filters;
