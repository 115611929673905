import React, { Fragment, useMemo } from "react";

import CompanyInput from "./CompanyInput";
import { DateInput } from "ra-ui-materialui";
import LocationScoutingDelegatesInput from "./LocationScoutingDelegatesInput";
import MovieTypeInput from "./MovieTypeInput";
import TextInput from "../../components/inputs/TextInput";
import { get } from "lodash";
import { makeStyles } from "@material-ui/core";
import { required } from "ra-core";
import useFieldLabel from "../../components/useFieldLabel";
import { useFormState } from "react-final-form";

const useStyles = makeStyles((theme) => ({
  inlineInput: {
    float: "left",
    marginRight: theme.spacing(2),
  },
}));

const LocationScoutingInput = ({ fullWidth, ...props }) => {
  const classes = useStyles();
  const formState = useFormState();
  const fieldLabel = useFieldLabel({
    resource: "ticket-location-scoutings",
  });
  const ticketTypeId = useMemo(
    () => get(formState.values, "ticket_type_id"),
    [formState.values]
  );
  if (ticketTypeId !== 2) {
    return null;
  }
  return (
    <Fragment>
      <TextInput
        {...props}
        source="ticket_location_scouting.location"
        maxLength={200}
        validate={required()}
        className={classes.inlineInput}
      />
      <DateInput
        {...props}
        source="ticket_location_scouting.date"
        validate={required()}
        className={classes.inlineInput}
      />
      <TextInput
        {...props}
        source="ticket_location_scouting.project_title"
        maxLength={100}
        validate={required()}
        fullWidth
      />
      <TextInput
        {...props}
        source="ticket_location_scouting.film_director"
        maxLength={100}
        validate={required()}
        className={classes.inlineInput}
      />
      <MovieTypeInput
        {...props}
        source="ticket_location_scouting.movie_type_id"
        validate={required()}
        className={classes.inlineInput}
      />
      <TextInput
        {...props}
        source="ticket_location_scouting.motivations"
        maxLength={4000}
        validate={required()}
        multiline
        fullWidth
      />
      <TextInput
        {...props}
        source="ticket_location_scouting.synopsis"
        maxLength={4000}
        validate={required()}
        multiline
        fullWidth
      />
      <CompanyInput
        {...props}
        source="ticket_location_scouting.company"
        fullWidth
      />
      <LocationScoutingDelegatesInput
        {...props}
        label={fieldLabel("ticket_location_scouting_delegates")}
        source="ticket_location_scouting.ticket_location_scouting_delegates"
        resource="ticket-location-scouting-delegates"
        fullWidth
      />
      <DateInput
        {...props}
        source="ticket_location_scouting.start_at"
        validate={required()}
        className={classes.inlineInput}
      />
      <DateInput
        {...props}
        source="ticket_location_scouting.end_at"
        validate={required()}
        className={classes.inlineInput}
      />
    </Fragment>
  );
};
export default LocationScoutingInput;
