import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Tooltip,
  makeStyles,
} from "@material-ui/core";

import EmailIcon from "@material-ui/icons/Email";
import React from "react";
import { useTranslate } from "react-admin";

const useStyles = makeStyles((theme) => ({
  icon: {
    minWidth: 40,
  },
  item: {
    color: theme.palette.primary[500],
  },
}));

const ContactMenu = ({ sidebarIsOpen }) => {
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        sidebarIsOpen && (
          <ListSubheader
            component="div"
            id="nested-list-subheader"
            style={{ textTransform: "uppercase" }}
          >
            {translate("ra.contacts")}
          </ListSubheader>
        )
      }
    >
      <Tooltip title="ufficioproduzioni@apuliafilmcommission.it">
        <ListItem
          button
          component="a"
          dense
          href="mailto:mailto:ufficioproduzioni@apuliafilmcommission.it"
          className={classes.item}
        >
          <ListItemIcon className={classes.icon}>
            <EmailIcon />
          </ListItemIcon>
          <ListItemText primary="ufficioproduzioni@" />
        </ListItem>
      </Tooltip>
      {/* <Tooltip title="Telegram">
        <ListItem
          button
          component="a"
          dense
          href="https://t.me/afc"
          target="_blank"
          rel="noopener noreferrer"
          className={classes.item}
        >
          <ListItemIcon className={classes.icon}>
            <TelegramIcon />
          </ListItemIcon>
          <ListItemText primary="https://t.me/afc" />
        </ListItem>
      </Tooltip> */}
    </List>
  );
};
export default ContactMenu;
