import React from "react";
import HelpIcon from "@material-ui/icons/Help";
import useHelpProvider from "./useHelpProvider";
import { Collapse, IconButton } from "@material-ui/core";

const HelpToggle = () => {
  const { hasHelp, visible, toggle } = useHelpProvider("HelpToggle");
  const handleToggle = () => toggle(!visible);
  return (
    <Collapse in={hasHelp && !visible}>
      <IconButton onClick={handleToggle} color="primary">
        <HelpIcon />
      </IconButton>
    </Collapse>
  );
};

export default HelpToggle;
