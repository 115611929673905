import AutocompleteInput from "../../components/inputs/AutocompleteInput";
import React from "react";
import { ReferenceInput } from "ra-ui-materialui";

const CompanyInput = (props) => (
  <ReferenceInput {...props} reference="companies">
    <AutocompleteInput optionText="name" />
  </ReferenceInput>
);

export default CompanyInput;
