import React, { useCallback } from "react";

import { SaveButton } from "react-admin";
import { get } from "lodash";
import { useForm } from "react-final-form";

const StateButton = ({
  handleSubmitWithRedirect,
  small,
  state,
  pristine,
  onClick,
  ...props
}) => {
  const form = useForm();

  const handleClick = useCallback(() => {
    form.change("state", state.code);
    if (onClick) {
      onClick();
    }
    handleSubmitWithRedirect("list");
  }, [state, form, handleSubmitWithRedirect, onClick]);

  return (
    <SaveButton
      {...props}
      color="primary"
      redirect="list"
      handleSubmitWithRedirect={handleClick}
      label={get(state, "label")}
      disableElevation
    />
  );
};

export default StateButton;
