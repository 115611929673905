import { EmailField } from "ra-ui-materialui";
import { Fragment } from "react";
import React from "react";
import { Typography } from "@material-ui/core";
import { get } from "lodash";

const AccountInfoField = ({ record }) => (
  <Fragment>
    <Typography variant="body1">
      {get(record, "name")} {get(record, "surname")}
    </Typography>
    <EmailField source="email" record={record} />
    <br />
    <Typography variant="caption">{get(record, "fiscal_code")}</Typography>
  </Fragment>
);

export default AccountInfoField;
