import {
  Edit as RaEdit,
  Toolbar as RaToolbar,
  SaveButton,
  SimpleForm,
  TextInput,
  required,
  useNotify,
  usePermissions,
} from "react-admin";

import CompaniesInput from "./inputs/CompaniesInput";
import PasswordInput from "../../../users/inputs/PasswordInput";
import React from "react";
import Title from "../../Title";
import { useFormStyles } from "../../forms/useStyles";
import useValidatableSave from "../../forms/useValidatableSave";

const Toolbar = (props) => (
  <RaToolbar {...props}>
    <SaveButton />
  </RaToolbar>
);

const EditForm = (props) => {
  const { permissions } = usePermissions();
  const notify = useNotify();
  const save = useValidatableSave({
    ...props,
    resource: "users/profile",
    redirect: "/",
    onSuccess: () => notify("ra.message.user_profile_updated"),
  });
  return (
    <SimpleForm
      {...props}
      toolbar={<Toolbar />}
      save={save}
      initialValues={{
        id: 0,
      }}
    >
      <TextInput source="email" validate={required()} />
      <PasswordInput permissions={permissions} />
      <TextInput source="profile.name" validate={required()} />
      <TextInput source="profile.surname" validate={required()} />
      <CompaniesInput source="companies" fullWidth />
    </SimpleForm>
  );
};

const ProfilePage = ({ staticContext, ...props }) => {
  const classes = useFormStyles();
  return (
    <RaEdit
      {...props}
      id={"profile"}
      resource="users"
      basePath="user-profile"
      title={<Title source="email" />}
      classes={classes}
    >
      <EditForm />
    </RaEdit>
  );
};

export default ProfilePage;
