import NotificationIcon from "./NotificationIcon";
import React from "react";
import { useGetIdentity } from "react-admin";

const Notification = () => {
  const { loading, loaded, identity } = useGetIdentity();
  if (loading || !loaded || identity === null || identity.id <= 0) {
    return null;
  }
  return <NotificationIcon />;
};

export default Notification;
