import "moment/locale/it";

import * as React from "react";

import { Admin, Resource } from "react-admin";

import Dashboard from "./dashboard";
import LoginPage from "./components/pages/Login";
import Menu from "./components/layout/Menu";
import PasswordResetPage from "./components/pages/PasswordResetPage";
import ProfilePage from "./components/pages/Profile";
import { Route } from "react-router-dom";
import SignupPage from "./components/pages/Signup";
import authProvider from "./authProvider";
import companies from "./companies";
import customLayout from "./components/layout/Layout";
import dataProvider from "./dataProvider";
import helpReducer from "./components/helpReducer";
import i18nProvider from "./i18nProvider";
import languageMessages from "./language-messages";
import notifications from "./notifications";
import pages from "./pages";
import redirects from "./redirects";
import theme from "./theme";
import tickets from "./tickets";
import ticketsCalendar from "./tickets-calendar";
import useI18nCatcher from "./useI18nCatcher";
import useLanguages from "./useLanguages";
import useLoadAll from "./components/workflow/useLoadAll";
import userDelegates from "./user-delegates";
import users from "./users";
import workflowReducer from "./components/workflow/reducers/workflowReducer";

const App = () => {
  useI18nCatcher();
  const { loading, languages } = useLanguages();
  const { loaded, data } = useLoadAll();
  if (loading) {
    return null;
  }

  if (!loaded) {
    return null;
  }

  return (
    <Admin
      title="afc.dev"
      menu={Menu}
      theme={theme}
      dashboard={Dashboard}
      dataProvider={dataProvider}
      authProvider={authProvider}
      i18nProvider={i18nProvider(languages)}
      loginPage={LoginPage}
      layout={customLayout}
      customReducers={{
        workflow: workflowReducer(data),
        help: helpReducer,
      }}
      customRoutes={[
        <Route
          key="user-profile"
          path="/user-profile"
          component={ProfilePage}
        />,
        <Route
          key="user-password-reset"
          path="/password-reset"
          component={PasswordResetPage}
          noLayout
          options={{ menu: false }}
        />,
        <Route
          key="user-signup"
          path="/signup"
          component={SignupPage}
          noLayout
          options={{ menu: false }}
        />,
      ]}
    >
      <Resource name="tickets" {...tickets} />
      <Resource name="tickets-calendar" {...ticketsCalendar} />
      <Resource name="ticket-types" />

      <Resource name="companies" {...companies} />
      <Resource name="notifications" {...notifications} />
      <Resource name="users" {...users} />
      <Resource name="user-delegates" {...userDelegates} />
      <Resource name="pages" {...pages} />
      <Resource name="redirects" {...redirects} />
      <Resource name="language-messages" {...languageMessages} />
      <Resource name="roles" />
      <Resource name="resources" />
      <Resource name="groups" />
      <Resource name="languages" />
      <Resource name="profile" />
      <Resource name="movie-types" />

      <Resource name="workflow/transactions/tickets" />
      <Resource name="workflow/transactions/companies" />
      <Resource name="workflow/transactions/user-delegates" />
    </Admin>
  );
};
export default App;
