import {
  DateField,
  List as RaList,
  TextField,
  useTranslate,
} from "react-admin";
import { green, orange, yellow } from "@material-ui/core/colors";

import ContentField from "./fields/ContentField";
import Empty from "./Empty";
import Filters from "./Filters";
import HelpProvider from "../components/HelpProvider";
import React from "react";
import ReadButton from "./ReadButton";
import ReadUnreadButton from "./ReadUnreadButton";
import ResponsiveList from "../components/lists/ResponsiveList";
import UnreadButton from "./UnreadButton";
import ViewField from "./fields/ViewField";
import { get } from "lodash";
import perPage from "../utils/perPage";
import { useListStyles } from "../components/lists/useStyles";
import { useTheme } from "@material-ui/core";

const BulkActionButtons = (props) => (
  <React.Fragment>
    <ReadButton
      label="resources.notifications.actions.sign_as_readed"
      {...props}
    />
    <UnreadButton
      label="resources.notifications.actions.sign_as_readed"
      {...props}
    />
  </React.Fragment>
);

const List = (props) => {
  const translate = useTranslate();
  const classes = useListStyles();
  const theme = useTheme();
  const isDark = get(theme, "palette.type", null) === "dark";
  const rowStyle = (record) => {
    if (isDark) {
      return {
        backgroundColor: record.readed ? green[100] : orange[100],
      };
    }
    return {
      backgroundColor: record.readed ? green[50] : yellow[50],
    };
  };
  return (
    <React.Fragment>
      <HelpProvider />
      <RaList
        {...props}
        exporter={false}
        perPage={perPage(10, 10)}
        filters={<Filters />}
        sort={{ field: "created", order: "DESC" }}
        classes={classes}
        empty={<Empty />}
        actions={false}
        bulkActionButtons={<BulkActionButtons />}
        title={translate("resources.notifications.name", 0)}
      >
        <ResponsiveList
          rowStyle={rowStyle}
          mobileHeader={
            <React.Fragment>
              <TextField
                source="title"
                style={{ fontWeight: "bold" }}
                variant="subtitle1"
              />
            </React.Fragment>
          }
          mobileActions={
            <React.Fragment>
              <ReadUnreadButton source="readed" />
              <ViewField source="resource" label="" />
            </React.Fragment>
          }
          mobileBody={
            <React.Fragment>
              <DateField source="created" showTime color="textSecondary" />
              <TextField source="content" />
            </React.Fragment>
          }
        >
          <ContentField label="resources.notifications.fields.content" />
          <DateField
            showTime
            source="created"
            options={{
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
            }}
          />
        </ResponsiveList>
      </RaList>
    </React.Fragment>
  );
};
export default List;
