import "react-big-calendar/lib/css/react-big-calendar.css";

import { Calendar, momentLocalizer } from "react-big-calendar";
import { Paper, makeStyles } from "@material-ui/core";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useDataProvider, useTranslate, useVersion } from "ra-core";

import HelpProvider from "../components/HelpProvider";
import { Title } from "ra-ui-materialui";
import moment from "moment";
import useMessages from "./useMessages";

const localizer = momentLocalizer(moment);
const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    "& *": {},
    "& .rbc-toolbar *": {
      textTransform: "uppercase",
    },
    "& .rbc-event-content": {
      whiteSpace: "break-spaces",
      fontSize: "10pt",
    },
  },
}));
const List = () => {
  const today = useMemo(() => moment(), []);
  const [events, setEvents] = useState([]);
  const [range, setRange] = useState({
    start: today.startOf("month").format("YYYY-MM-DD 00:00"),
    end: today.endOf("month").format("YYYY-MM-DD 23:59"),
  });
  const translate = useTranslate();
  const messages = useMessages(translate);
  const classes = useStyles();
  const version = useVersion();
  const dataProvider = useDataProvider();

  useEffect(() => {
    async function fetchData() {
      const { data } = await dataProvider.get("tickets/calendar", {
        query: range,
      });
      setEvents(
        data.map((event) => ({
          ...event,
          start: moment(event.start),
          end: moment(event.end).add(1, "hour"),
        }))
      );
    }
    fetchData();
  }, [version, range, dataProvider]);

  const handleRangeChange = (e) =>
    setRange({
      start: moment(e.start).format("YYYY-MM-DD 00:00"),
      end: moment(e.end).format("YYYY-MM-DD 23:59"),
    });
  return (
    <Fragment>
      <HelpProvider />
      <Title title="resources.tickets-calendar.name" />
      <Paper className={classes.root}>
        <Calendar
          localizer={localizer}
          defaultDate={today.toDate()}
          defaultView="month"
          events={events}
          views={["month"]}
          messages={messages}
          style={{ height: "calc(100vh - 160px)" }}
          onRangeChange={handleRangeChange}
        />
      </Paper>
    </Fragment>
  );
};
export default List;
