import React, { Fragment, useMemo } from "react";

import DateTimeInput from "../../components/inputs/DateTimeInput";
import MovieTypeInput from "./MovieTypeInput";
import TextInput from "../../components/inputs/TextInput";
import { get } from "lodash";
import { required } from "ra-core";
import { useFormState } from "react-final-form";

const FirstInterviewInput = ({ fullWidth, ...props }) => {
  const formState = useFormState();
  const ticketTypeId = useMemo(
    () => get(formState.values, "ticket_type_id"),
    [formState.values]
  );
  if (ticketTypeId !== 3) {
    return null;
  }
  return (
    <Fragment>
      <TextInput
        {...props}
        source="ticket_first_interview.project_title"
        maxLength={200}
        fullWidth
        validate={required()}
      />
      <TextInput
        {...props}
        source="ticket_first_interview.direction"
        maxLength={200}
        fullWidth
        validate={required()}
      />
      <MovieTypeInput
        {...props}
        label="resources.tickets.fields.ticket_first_interview.movie_type_id"
        source="ticket_first_interview.movie_type_id"
        validate={required()}
      />
      <TextInput
        {...props}
        source="ticket_first_interview.motivations"
        maxLength={4000}
        fullWidth
        multiline
        rows={5}
        validate={required()}
      />
      <DateTimeInput
        {...props}
        source="ticket_first_interview.planned_date"
        validate={required()}
      />
    </Fragment>
  );
};
export default FirstInterviewInput;
