import { useMemo } from "react";

const useMessages = (translate) => {
  return useMemo(
    () => ({
      allDay: translate("app.label.calendar.message.all_day"),
      previous: translate("app.label.calendar.message.previous"),
      next: translate("app.label.calendar.message.next"),
      today: translate("app.label.calendar.message.today"),
      month: translate("app.label.calendar.message.month"),
      week: translate("app.label.calendar.message.week"),
      day: translate("app.label.calendar.message.day"),
      agenda: translate("app.label.calendar.message.agenda"),
      date: translate("app.label.calendar.message.date"),
      time: translate("app.label.calendar.message.time"),
      event: translate("app.label.calendar.message.event"),
    }),
    [translate]
  );
};
export default useMessages;
