import { CircularProgress, Typography } from "@material-ui/core";
import { Login, LoginForm, useRedirect, useTranslate } from "react-admin";
import React, { useEffect, useMemo } from "react";
import authenticate, {
  clearSignResponse,
  setLoggedIn,
  setSignResponse,
} from "./actions/authenticate";

import { APP_URL } from "../../../config";
import LoginWithSpidButton from "./buttons/LoginWithSpidButton";
import PasswordResetButton from "./buttons/PasswordResetButton";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  spid: {
    margin: theme.spacing(2),
  },
  sc: {
    margin: theme.spacing(2),
    maxWidth: 300 - theme.spacing(2),
    display: "flex",
    flexDirection: "column",
  },
  scLoader: {
    alignSelf: "center",
  },
  help: {
    maxWidth: 300 - theme.spacing(2),
    marginTop: theme.spacing(2),
    display: "block",
    clear: "both",
  },
}));

const LoginPage = ({ location: { search } }) => {
  const classes = useStyles();
  const redirect = useRedirect();
  const translate = useTranslate();
  const action = useMemo(() => {
    const queryString = new URLSearchParams(search);
    const type = queryString.get("type");
    switch (type) {
      case "staff":
      case "local":
        return "login";
      case "sc":
        return "sc";
      default:
        return "spid";
    }
  }, [search]);
  useEffect(() => {
    if (action !== "sc") {
      return;
    }
    const doAuth = async () => {
      const { success, data } = await authenticate({ search });
      if (!success) {
        setSignResponse(search);
        const r = `#/signup`;
        document.location = r;
      } else {
        clearSignResponse();
        setLoggedIn({ data });
        redirect("/");
      }
    };
    doAuth();
  }, [action, search, redirect]);
  return (
    <Login backgroundImage="" className={classes.background}>
      <div style={{ textAlign: "center" }}>
        <img src={`${APP_URL}/boilerplate_theme/img/favicon.png`} alt="AFC" />
      </div>
      {action === "login" && (
        <div>
          <LoginForm />
          <PasswordResetButton />
        </div>
      )}
      {action === "spid" && (
        <div className={classes.spid}>
          <LoginWithSpidButton />
          <div className={classes.help}>
            <Typography variant="body2">{translate("ra.auth.help")}</Typography>
          </div>
        </div>
      )}
      {action === "sc" && (
        <div className={classes.sc}>
          <CircularProgress className={classes.scLoader} color="primary" />
        </div>
      )}
    </Login>
  );
};
export default LoginPage;
