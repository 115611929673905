import {
  CircularProgress,
  Grid,
  Typography,
  makeStyles,
} from "@material-ui/core";
import {
  Create,
  Notification,
  Toolbar as RaToolbar,
  SaveButton,
} from "ra-ui-materialui";
import React, { useEffect, useMemo, useState } from "react";
import {
  clearSignResponse,
  getSignResponse,
} from "../Login/actions/authenticate";
import { useRedirect, useTranslate } from "ra-core";

import { APP_URL } from "../../../config";
import AccountStepInput from "./inputs/AccountStepInput";
import CompanyStepInput from "./inputs/CompanyStepInput";
import SignupForm from "./SignupForm";
import SignupStepper from "./SignupStepper";
import classnames from "classnames";
import { get } from "lodash";
import load from "./actions/load";
import { useFormStyles } from "../../forms/useStyles";
import useValidatableSave from "../../forms/useValidatableSave";

const useStyles = makeStyles((theme) => ({
  main: {
    paddingTop: "2rem",
    minHeight: "100vh",
    width: "auto",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundImage:
      "radial-gradient(circle at 50% 14em, #313264 0%, #00023b 60%, #00023b 100%)",
    "& h6": {
      color: "white",
      textAlign: "center",
    },
    "& .create-page > div:first-child": {
      width: "auto",
    },
    [theme.breakpoints.down("sm")]: {
      paddingTop: "1rem",
    },
  },
  root: {
    [theme.breakpoints.down("sm")]: {
      width: "auto",
    },
  },
  centered: {
    textAlign: "center",
  },
}));
const Toolbar = ({ basePath, ...props }) => {
  return (
    <RaToolbar {...props}>
      <SaveButton label="ra.auth.signup" />
    </RaToolbar>
  );
};

const SignupPage = ({ staticContext, ...props }) => {
  const search = getSignResponse();
  const classes = useFormStyles();
  const redirect = useRedirect();
  const localClasses = useStyles();
  const translate = useTranslate();
  const save = useValidatableSave({
    resource: "users",
    notifyErrors: false,
    onSuccess: () => {
      setTimeout(() => {
        document.location.href = `#/login${search}`;
        document.location.reload();
      }, 100);
    },
  });
  const [profile, setProfile] = useState(null);
  useEffect(() => {
    const doLoad = async () => {
      if (!search) {
        redirect("/login");
      }
      const { success, data } = await load({ search });
      if (success) {
        setProfile(data);
      } else {
        redirect("/login");
        clearSignResponse();
      }
    };
    doLoad();
  }, [search, redirect]);
  const companyReadonly = useMemo(
    () => get(profile, "companyReadonly", false),
    [profile]
  );

  return (
    <Grid container justify="center" className={localClasses.main}>
      <Grid
        item
        lg={4}
        md={6}
        sm={10}
        xs={12}
        className={classnames(profile === null && localClasses.centered)}
      >
        <img src={`${APP_URL}/boilerplate_theme/img/favicon.png`} alt="AFC" />
        {profile === null && <br />}
        {profile === null && <CircularProgress />}
        {profile !== null && (
          <Typography variant="h6">{translate("ra.signup.title")}</Typography>
        )}

        {profile !== null && (
          <Create
            {...props}
            className={localClasses.root}
            classes={classes}
            basePath="users"
            resource="users"
          >
            <SignupForm
              save={save}
              toolbar={<Toolbar />}
              initialValues={profile}
            >
              <SignupStepper fullWidth>
                <AccountStepInput />
                <CompanyStepInput disabled={companyReadonly} source="company" />
              </SignupStepper>
            </SignupForm>
          </Create>
        )}
        <Notification />
      </Grid>
    </Grid>
  );
};
export default SignupPage;
