import { Toolbar as RaToolbar, SaveButton } from "react-admin";
import React, { useCallback, useMemo } from "react";

import BackButton from "../buttons/BackButton";
import ButtonListMenu from "./ButtonListMenu";
import StateButton from "../buttons/StateButton";
import { get } from "lodash";
import { useForm } from "react-final-form";
import { useRoles } from "../../../authProvider";
import { useToolbarStyles } from "./useStyles";
import useWorkflow from "../useWorkflow";

const Toolbar = ({ children, mutationMode, ...props }) => {
  const form = useForm();
  const { handleSubmitWithRedirect, record } = props;
  const classes = useToolbarStyles();
  const roles = useRoles();
  const workflow = useWorkflow({ resource: props.resource });
  const { states, save } = useMemo(() => {
    const save =
      workflow && workflow.canEdit(roles, record) && get(record, "id", 0) > 0;
    const states = (workflow && workflow.getNextStates(roles, record)) || [];
    return { states, save };
  }, [workflow, record, roles]);

  const handleClick = useCallback(() => {
    form.change(
      "state",
      get(record, "transaction.state", get(record, "state"))
    );
    handleSubmitWithRedirect("list");
  }, [form, record, handleSubmitWithRedirect]);
  if (!record) {
    return null;
  }
  return (
    <RaToolbar {...props} classes={classes}>
      {save && (
        <SaveButton
          color="secondary"
          {...props}
          handleSubmitWithRedirect={handleClick}
          disableElevation
        />
      )}
      {states.length > 2 && <ButtonListMenu states={states} {...props} />}
      {states.length < 3 &&
        states.map((state) => (
          <StateButton key={get(state, "code")} state={state} {...props} />
        ))}
      {React.Children.count(children) > 0 &&
        React.Children.map(children, (child, key) =>
          React.cloneElement(child, { ...props, key })
        )}

      <BackButton />
    </RaToolbar>
  );
};

export default Toolbar;
