import { Alert, AlertTitle } from "@material-ui/lab";
import React, { Fragment } from "react";

import AddressInput from "../../../../companies/inputs/AddressInput";
import TextInput from "../../../inputs/TextInput";
import useFieldLabel from "../../../useFieldLabel";
import { useTranslate } from "react-admin";

const CompanyStepInput = ({ source, ...props }) => {
  const fieldLabel = useFieldLabel({ resource: "companies" });
  const translate = useTranslate();
  return (
    <Fragment>
      {props.disabled && (
        <Alert>
          <AlertTitle>{translate("resources.users.signup.title")}</AlertTitle>
          {translate("resources.users.signup.delegation_message")}
        </Alert>
      )}
      <TextInput
        {...props}
        label={fieldLabel("name")}
        source={`${source}.name`}
        maxLength={50}
      />
      <TextInput
        {...props}
        label={fieldLabel("vat_code")}
        source={`${source}.vat_code`}
        maxLength={11}
      />
      <TextInput
        {...props}
        label={fieldLabel("country")}
        source={`${source}.country`}
        maxLength={50}
      />
      <AddressInput
        {...props}
        source={`${source}.legal_address`}
        required
        fullWidth
        resource="companies"
      />
      <AddressInput
        {...props}
        source={`${source}.operative_address`}
        fullWidth
        resource="companies"
      />
    </Fragment>
  );
};
export default CompanyStepInput;
