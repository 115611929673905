import CompanyIcon from "@material-ui/icons/Business";
import DashboardIcon from "@material-ui/icons/Dashboard";
import LanguageMessagesIcon from "@material-ui/icons/Language";
import NotificationsIcon from "@material-ui/icons/Notifications";
import PagesIcon from "@material-ui/icons/Pages";
import RedirectsIcon from "@material-ui/icons/Cached";
import RoleIcon from "@material-ui/icons/Lock";
import TicketCalendarIcon from "@material-ui/icons/Today";
import TicketIcon from "@material-ui/icons/LiveHelp";
import UserDelegateIcon from "@material-ui/icons/PeopleAlt";
import UserIcon from "@material-ui/icons/Face";
import UserPaymentIcon from "@material-ui/icons/AccountBalanceWallet";

export {
  DashboardIcon,
  CompanyIcon,
  LanguageMessagesIcon,
  NotificationsIcon,
  PagesIcon,
  RedirectsIcon,
  RoleIcon,
  TicketCalendarIcon,
  TicketIcon,
  UserIcon,
  UserPaymentIcon,
  UserDelegateIcon,
};
