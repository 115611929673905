import { Divider, Typography } from "@material-ui/core";
import React, { Fragment } from "react";

import AddressInput from "../../companies/inputs/AddressInput";
import AutocompleteInput from "../../components/inputs/AutocompleteInput";
import { ReferenceInput } from "react-admin";
import TextInput from "../../components/inputs/TextInput";
import useFieldLabel from "../../components/useFieldLabel";

const CompanyInput = ({ autocomplete = false, source, ...props }) => {
  const fieldLabel = useFieldLabel({ resource: "companies" });
  if (autocomplete) {
    return (
      <ReferenceInput {...props} source={source}>
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
    );
  }
  return (
    <Fragment>
      <br />
      <Typography variant="subtitle1">{fieldLabel(source)}</Typography>
      <Divider />
      <TextInput
        {...props}
        label={fieldLabel("name")}
        source={`${source}.name`}
        maxLength={50}
      />
      <TextInput
        {...props}
        label={fieldLabel("vat_code")}
        source={`${source}.vat_code`}
        maxLength={11}
      />
      <TextInput
        {...props}
        label={fieldLabel("country")}
        source={`${source}.country`}
        maxLength={50}
      />
      <AddressInput
        {...props}
        label={fieldLabel("legal_address")}
        source={`${source}.legal_address`}
        fullWidth
        resource="companies"
      />
      <AddressInput
        {...props}
        label={fieldLabel("operative_address")}
        source={`${source}.operative_address`}
        fullWidth
        resource="companies"
      />
    </Fragment>
  );
};
export default CompanyInput;
