import {
  SimpleForm,
  email,
  minLength,
  required,
  useAuthProvider,
  useNotify,
  usePermissions,
  useRedirect,
} from "react-admin";

import CompanyInput from "./inputs/CompanyInput";
import NotesInput from "../components/workflow/inputs/NotesInput";
import NotesPrivateInput from "../components/workflow/inputs/NotesPrivateInput";
import PermsInput from "./inputs/PermsInput";
import React from "react";
import RequestMessageField from "./fields/RequestMessageField";
import StateInfoField from "../components/workflow/fields/StateInfoField";
import TextInput from "../components/inputs/TextInput";
import Toolbar from "../components/workflow/form/Toolbar";
import TransactionLogsField from "../components/workflow/fields/TransactionLogsField";
import Workflow from "../components/workflow";
import { get } from "lodash";
import useValidatableSave from "../components/forms/useValidatableSave";

const Form = ({ create = false, ...props }) => {
  const authProvider = useAuthProvider();
  const redirect = useRedirect();
  const notify = useNotify();
  const save = useValidatableSave({
    ...props,
    redirect: undefined,
    onSuccess: (response) => {
      const state = get(response, "data.state", "pending");
      if (state === "active") {
        authProvider.refresh();
      }
      redirect("list", props.basePath);
      notify(`resources.user-delegates.notify.${state}`);
    },
  });
  const { loaded, permissions } = usePermissions();
  const isAdmin = loaded && permissions(["admin"]);
  return (
    <SimpleForm
      {...props}
      save={save}
      toolbar={<Toolbar />}
      initialValues={{ is_private: false, state: "pending" }}
    >
      <Workflow.Input
        source="request_message"
        component={<RequestMessageField />}
      />
      <Workflow.Input
        source="company_id"
        validate={required()}
        component={<CompanyInput />}
      />
      <Workflow.Input
        source="name"
        validate={required()}
        maxLength={100}
        component={<TextInput />}
      />
      <Workflow.Input
        source="surname"
        validate={required()}
        maxLength={100}
        component={<TextInput />}
      />
      <Workflow.Input
        source="fiscal_code"
        validate={[required(), minLength(16)]}
        maxLength={16}
        component={<TextInput />}
      />
      <Workflow.Input
        source="email"
        maxLength={100}
        validate={[required(), email()]}
        component={<TextInput type="email" />}
      />
      <Workflow.Input
        source="user_delegate_perms"
        validate={required()}
        component={<PermsInput />}
        fullWidth
      />
      <TransactionLogsField admin={isAdmin} fullWidth />
      <NotesInput source="notes" fullWidth />
      <NotesPrivateInput admin={isAdmin} source="is_private" fullWidth />
      <StateInfoField />
    </SimpleForm>
  );
};
export default Form;
