import { ArrayInput, SimpleFormIterator } from "react-admin";

import CompanyInput from "./CompanyInput";
import React from "react";
import RoleInput from "./RoleInput";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .ra-input": {
      display: "inline-block",
      paddingTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    "& ul": {
      marginTop: theme.spacing(4),
    },
  },
}));
const CompaniesInput = (props) => {
  const classes = useStyles();
  return (
    <ArrayInput {...props} classes={classes}>
      <SimpleFormIterator>
        <CompanyInput source="company_id" />
        <RoleInput source="role_id" />
      </SimpleFormIterator>
    </ArrayInput>
  );
};
export default CompaniesInput;
