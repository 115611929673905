import { APP_URL } from "../../../../config";

export const setLoggedIn = ({ data }) => {
  localStorage.setItem("token", data.token);
  localStorage.setItem("roles", JSON.stringify(data.roles));
  localStorage.setItem("username", data.username);
  localStorage.setItem("profile", JSON.stringify(data.profile));
  localStorage.setItem("companies", JSON.stringify(data.companies));
};
export const setSignResponse = (r) => localStorage.setItem("r", r);
export const getSignResponse = () => localStorage.getItem("r");
export const clearSignResponse = () => localStorage.removeItem("r");
const authenticate = ({ search }) =>
  fetch(`${APP_URL}spid/authenticate${search}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .catch((error) => error);

export default authenticate;
