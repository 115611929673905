import { Alert, AlertTitle } from "@material-ui/lab";

import React from "react";
import { get } from "lodash";
import { makeStyles } from "@material-ui/core";
import { useTranslate } from "ra-core";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
}));
const RequestMessageField = ({ record, disabled }) => {
  const translate = useTranslate();
  const classes = useStyles();
  const id = get(record, "id", 0);
  if (disabled || id <= 0) {
    return null;
  }
  return (
    <Alert severity="info" className={classes.root}>
      <AlertTitle>
        {translate("resources.user-delegates.fields.request_message.title")}
      </AlertTitle>
      {translate("resources.user-delegates.fields.request_message.content", {
        companyName: get(record, "company.name"),
      })}
    </Alert>
  );
};

export default RequestMessageField;
