import { grey, pink, red } from "@material-ui/core/colors";

import { createMuiTheme } from "@material-ui/core/styles";

export default createMuiTheme({
  type: "light",
  typography: {
    fontSize: 17,
  },
  palette: {
    background: {
      default: "#EDECEC",
    },
    primary: pink,
    secondary: grey,
    error: red,
  },
});
