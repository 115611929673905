import getHeaders from "./authHeaders";

const { API_URL } = require("./config");
export const useRoles = () =>
  JSON.parse(localStorage.getItem("roles") || "[]") || [];

export const useCompanies = () =>
  JSON.parse(localStorage.getItem("companies") || "[]") || [];

/**
 * Restituisce l'elenco delle società di produzione per cui l'utente è inquadrato come legale rappresentante.
 * @returns array
 */
export const useLegalRepresentativeCompanies = () => {
  const companies = useCompanies();
  return companies
    .filter((c) => c.role && c.role.code === "legal-representative")
    .map((c) => c.company);
};

const authProvider = {
  login: (params) => {
    const { username, password } = params;
    const requestURL = `${API_URL}/users/login`;
    const request = new Request(requestURL, {
      method: "POST",
      body: JSON.stringify({ username, password }),
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
    });
    return fetch(request)
      .then((response) => response.json())
      .then(({ data }) => {
        if (data.code === 401) {
          throw new Error(data.message);
        }
        localStorage.setItem("token", data.token);
        localStorage.setItem("roles", JSON.stringify(data.roles));
        localStorage.setItem("profile", JSON.stringify(data.profile));
        localStorage.setItem("companies", JSON.stringify(data.companies));
      });
  },
  logout: () => {
    ["token", "roles", "profile", "companies"].forEach((param) => {
      localStorage.removeItem(param);
      localStorage.removeItem(`admin_${param}`);
    });
    return Promise.resolve();
  },
  checkAuth: () =>
    localStorage.getItem("token") ? Promise.resolve() : Promise.reject(),
  checkError: (error) => {
    if (error.status === 401) {
      return Promise.reject();
    }
    return Promise.resolve();
  },
  getPermissions: () => {
    let roles = JSON.parse(localStorage.getItem("roles"));
    return Promise.resolve(
      (v) => roles && roles.some((r) => v.includes(r.code))
    );
  },

  getIdentity: () => {
    const profile = JSON.parse(localStorage.getItem("profile"));
    return Promise.resolve(profile);
  },

  impersonate: (id) => {
    const requestURL = `${API_URL}/users/impersonate/?id=${id}`;
    const request = new Request(requestURL, {
      method: "POST",
      headers: getHeaders(),
    });
    return fetch(request)
      .then((response) => response.json())
      .then(({ success, data }) => {
        if (!success) {
          throw new Error(data.message);
        }
        ["token", "roles", "username", "profile", "companies"].forEach(
          (param) => {
            const toSaveParam = `admin_${param}`;
            localStorage.setItem(toSaveParam, localStorage.getItem(param));
            localStorage.setItem(
              param,
              ["profile", "roles", "companies"].indexOf(param) !== -1
                ? JSON.stringify(data[param])
                : data[param]
            );
          }
        );
        localStorage.setItem("impersonate", true);
      });
  },
  stopImpersonate() {
    ["token", "roles", "username", "profile", "companies"].forEach((param) => {
      const savedParam = `admin_${param}`;
      localStorage.setItem(param, localStorage.getItem(savedParam));
      localStorage.removeItem(savedParam);
    });
    localStorage.setItem("impersonate", false);
    return Promise.resolve();
  },
  refresh() {
    const requestURL = `${API_URL}/users/refresh`;
    const request = new Request(requestURL, {
      method: "POST",
      headers: getHeaders(),
    });
    return fetch(request)
      .then((response) => response.json())
      .then(({ data }) => {
        localStorage.setItem("roles", JSON.stringify(data.roles));
        localStorage.setItem("profile", JSON.stringify(data.profile));
      });
  },
};

export default authProvider;
