import Create from "./Create";
import Edit from "./Edit";
import List from "./List";
import { TicketIcon } from "../icons";

export default {
  options: {
    group: "dashboard",
    roles: ["user", "admin"],
  },
  icon: TicketIcon,
  list: List,
  edit: Edit,
  create: Create,
};
