import { Button, makeStyles } from "@material-ui/core";

import { APP_URL } from "../../../../config";
import React from "react";
import logo from "../../../../assets/regione_puglia.svg";
import { useTranslate } from "react-admin";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: "#1173d4",
  },
  leftIcon: {
    width: 16,
    marginRight: theme.spacing(2),
  },
  text: {
    textTransform: "none",
  },
}));

const LoginWithSpidButton = () => {
  const classes = useStyles();
  const translate = useTranslate();
  return (
    <Button
      disableElevation
      className={classes.root}
      href={`${APP_URL}spid/auth?b=client`}
      variant="contained"
      type="button"
      color="primary"
      size="large"
    >
      <img src={logo} className={classes.leftIcon} alt="Regione Puglia" />
      <span
        className={classes.text}
        dangerouslySetInnerHTML={{ __html: translate("ra.auth.login") }}
      />
    </Button>
  );
};
export default LoginWithSpidButton;
