import { Divider, Grid, Typography } from "@material-ui/core";
import React, { Fragment } from "react";
import { useTranslate, required as validateRequired } from "ra-core";

import TextInput from "../../components/inputs/TextInput";
import useFieldLabel from "../../components/useFieldLabel";

const AddressInput = ({
  label = undefined,
  source,
  record,
  required = false,
  disabled = false,
}) => {
  const fieldLabel = useFieldLabel({ resource: "companies" });
  const translate = useTranslate();
  return (
    <Fragment>
      <br />
      <Typography variant="caption">
        {(label && translate(label)) ||
          fieldLabel(source.replace("company.", ""))}
      </Typography>
      <Divider />
      <Grid container spacing={2}>
        <Grid item lg={6} md={8} sm={8} xs={12}>
          <TextInput
            label={fieldLabel("address")}
            source={`${source}.address`}
            record={record}
            validate={required ? validateRequired() : null}
            fullWidth
            disabled={disabled}
          />
        </Grid>
        <Grid item lg={4} md={4} sm={4} xs={12}>
          <TextInput
            label={fieldLabel("city")}
            source={`${source}.city`}
            record={record}
            validate={required ? validateRequired() : null}
            fullWidth
            disabled={disabled}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
};
export default AddressInput;
