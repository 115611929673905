import {
  DateField,
  List as RaList,
  TextField,
  usePermissions,
} from "react-admin";
import React, { Fragment } from "react";
import { useListStyles, useSelector } from "../components/lists/useStyles";

import Filters from "./Filters";
import HelpProvider from "../components/HelpProvider";
import LongTextField from "../components/fields/LongTextField";
import ResponsiveList from "../components/lists/ResponsiveList";
import Workflow from "../components/workflow";
import perPage from "../utils/perPage";
import useFieldLabel from "../components/useFieldLabel";

const List = (props) => {
  const { loaded, permissions } = usePermissions();
  const sidebarOpen = useSelector((state) => state.admin.ui.sidebarOpen);
  const classes = useListStyles({ sidebarOpen });
  const isAdmin = loaded && permissions(["admin"]);
  const fieldLabel = useFieldLabel(props);
  return (
    <Fragment>
      <HelpProvider />
      <RaList
        {...props}
        classes={classes}
        perPage={perPage(25, 25)}
        filters={<Filters />}
        sort={{ field: "id", order: "DESC" }}
        bulkActionButtons={false}
      >
        <ResponsiveList>
          <TextField source="id" />
          <TextField source="protocol" />
          {isAdmin && (
            <TextField
              source="user.nice_name"
              sortBy="Users.username"
              label={fieldLabel("user_id")}
            />
          )}
          <LongTextField
            source="company.name"
            sortBy="Companies.name"
            label={fieldLabel("company")}
          />
          <LongTextField
            source="ticket_type.name"
            sortBy="TicketTypes.name"
            label={fieldLabel("ticket_type_id")}
          />
          <Workflow.StateCollectionInput
            source="state"
            readonly={!isAdmin}
            admin={isAdmin}
          />
          <DateField source="modified" showTime />
          <Workflow.EditButton />
        </ResponsiveList>
      </RaList>
    </Fragment>
  );
};

export default List;
