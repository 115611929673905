import ListField, { makeRedirect } from "../../components/fields/ListField";

import LongTextField from "../../components/fields/LongTextField";
import React from "react";
import { TextField } from "ra-ui-materialui";
import { useRedirect } from "ra-core";

const UsersField = (props) => {
  const redirect = useRedirect();
  return (
    <ListField
      {...props}
      reference="users"
      target="company_id"
      canModify={true}
      canCreate={false}
      canRemove={false}
      sort={{ field: "id", order: "ASC" }}
      removeRedirect={() => redirect(makeRedirect({ ...props, tab: 1 }))}
    >
      <TextField source="id" />
      <LongTextField source="username" />
      <TextField source="profile.name" sortBy="UserProfiles.name" />
      <TextField source="profile.surname" sortBy="UserProfiles.surname" />
      <TextField source="profile.phone" sortBy="UserProfiles.phone" />
    </ListField>
  );
};

export default UsersField;
