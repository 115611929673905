import { TabbedForm, required, usePermissions } from "react-admin";

import AddressInput from "./inputs/AddressInput";
import { FormTab } from "../components/workflow";
import NotesInput from "../components/workflow/inputs/NotesInput";
import NotesPrivateInput from "../components/workflow/inputs/NotesPrivateInput";
import React from "react";
import StateInfoField from "../components/workflow/fields/StateInfoField";
import TextInput from "../components/inputs/TextInput";
import Toolbar from "../components/workflow/form/Toolbar";
import TransactionLogsField from "../components/workflow/fields/TransactionLogsField";
import UsersField from "./fields/UsersField";
import useValidatableSave from "../components/forms/useValidatableSave";

const Form = ({ create = false, ...props }) => {
  const save = useValidatableSave({ ...props, redirect: undefined });
  const { loaded, permissions } = usePermissions();
  const isAdmin = loaded && permissions(["admin"]);
  return (
    <TabbedForm
      {...props}
      toolbar={<Toolbar />}
      save={save}
      initialValues={{
        is_private: false,
        legal_address: { type: "legal" },
        operative_address: { type: "operative" },
      }}
    >
      <FormTab label={"resources.companies.tabs.general"}>
        <TextInput
          source="name"
          maxLength={100}
          fullWidth
          validate={required()}
        />
        <TextInput source="country" maxLength={100} validate={required()} />
        <TextInput source="vat_code" maxLength={20} validate={required()} />
        <AddressInput source="legal_address" fullWidth required />
        <AddressInput source="operative_address" fullWidth />
        <TransactionLogsField admin={isAdmin} fullWidth />
        <NotesInput source="notes" fullWidth />
        <NotesPrivateInput admin={isAdmin} source="is_private" fullWidth />
        <StateInfoField />
      </FormTab>
      {!create && (
        <FormTab label={"resources.companies.tabs.users"}>
          <UsersField source="users" fullWidth />
        </FormTab>
      )}
    </TabbedForm>
  );
};
export default Form;
