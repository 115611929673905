import { Alert, AlertTitle } from "@material-ui/lab";

import React from "react";
import { get } from "lodash";
import { makeStyles } from "@material-ui/core";
import useWorkflow from "../useWorkflow";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.primary[500],
  },
}));

const StateInfoField = ({ resource, record }) => {
  const classes = useStyles();
  const workflow = useWorkflow({ resource });
  if (!workflow) {
    return null;
  }
  const state = workflow.getState(record);
  if (!state) {
    return null;
  }
  return (
    <Alert variant="filled" severity="success" className={classes.root}>
      <AlertTitle>{get(state, "name")}</AlertTitle>
      {get(state, "description")}
    </Alert>
  );
};
export default StateInfoField;
