import { ReferenceInput, SelectInput } from "ra-ui-materialui";

import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  root: {
    minWidth: 255,
  },
}));
const MovieTypeInput = (props) => {
  const classes = useStyles();
  return (
    <ReferenceInput
      {...props}
      className={classes.root}
      reference="movie-types"
      sort={{ field: "name", order: "ASC" }}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
  );
};
export default MovieTypeInput;
