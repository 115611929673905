import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Button from "@material-ui/core/Button";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import React from "react";
import StateButton from "../buttons/StateButton";
import { useTranslate } from "react-admin";
import { withStyles } from "@material-ui/core/styles";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
    "& button": {
      width: "100%",
      display: "block",
      textAlign: "left",
    },
    "& .MuiMenuItem-root:focus": {
      backgroundColor: "transparent",
    },
    "& li:hover": {
      backgroundColor: "transparent",
    },
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const ButtonListMenu = ({ states, ...props }) => {
  const translate = useTranslate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [state, setState] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleState = (state) => {
    setState(state.label);
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="contained"
        color="primary"
        onClick={handleClick}
      >
        <DoubleArrowIcon />
        {props.saving ? `${state}...` : translate("ra.workflow.do_action")}
      </Button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {states.map((state) => (
          <StyledMenuItem key={state.code}>
            <StateButton
              {...props}
              state={state}
              color="default"
              variant="text"
              icon={<ArrowForwardIosIcon />}
              onClick={handleState.bind(this, state)}
            />
          </StyledMenuItem>
        ))}
      </StyledMenu>
    </div>
  );
};

export default ButtonListMenu;
