import * as React from "react";

import { CircularProgress, makeStyles } from "@material-ui/core";

import useNetwork from "../../useNetwork";

const useStyles = makeStyles((theme) => ({
  logo: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(4),
  },
}));

const Logo = ({ dark = false }) => {
  const classes = useStyles();
  const { loading, network } = useNetwork();
  if (loading) {
    return <CircularProgress />;
  }
  return (
    <React.Fragment>
      <img
        className={classes.logo}
        src={dark ? network.logo_white : network.logo_dark}
        alt={network.name}
        height={40}
      />
    </React.Fragment>
  );
};

export default Logo;
