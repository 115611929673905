import { ReferenceInput, SelectInput } from "react-admin";

import React from "react";

const RoleInput = (props) => (
  <ReferenceInput {...props} reference="roles" fullWidth>
    <SelectInput optionText="name" />
  </ReferenceInput>
);
export default RoleInput;
