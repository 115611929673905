import { Alert, AlertTitle } from "@material-ui/lab";
import { Collapse, IconButton, makeStyles } from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import React from "react";
import { useTranslate } from "react-admin";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("xs")]: {
      borderRadius: 0,
    },
  },
  filledSuccess: {
    backgroundColor: theme.palette.primary[500],
    marginBottom: theme.spacing(1),
  },
  helpNeeded: {
    color: "white",
  },
}));
const HelpBlock = ({
  title = null,
  message = null,
  visible = true,
  toggle,
  dispatch,
  ...props
}) => {
  const translate = useTranslate();
  const classes = useStyles();
  const handleToggle = () => toggle();

  return (
    <Collapse in={visible}>
      <Alert
        severity="success"
        variant="filled"
        classes={{ filledSuccess: classes.filledSuccess }}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={handleToggle}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
        {...props}
      >
        {title && <AlertTitle>{translate(title)}</AlertTitle>}
        <div dangerouslySetInnerHTML={{ __html: translate(message) }} />
      </Alert>
    </Collapse>
  );
};
export default HelpBlock;
