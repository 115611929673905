import React, { Fragment, useMemo } from "react";

import CompanyInput from "./CompanyInput";
import { DateInput } from "ra-ui-materialui";
import MovieTypeInput from "./MovieTypeInput";
import PhotoScoutingLocationsInput from "./PhotoScoutingLocationsInput";
import TextInput from "../../components/inputs/TextInput";
import { get } from "lodash";
import { makeStyles } from "@material-ui/core";
import { required } from "ra-core";
import useFieldLabel from "../../components/useFieldLabel";
import { useFormState } from "react-final-form";

const useStyles = makeStyles((theme) => ({
  inlineInput: {
    float: "left",
    marginRight: theme.spacing(2),
  },
}));

const PhotoScoutingInput = ({ fullWidth, ...props }) => {
  const classes = useStyles();
  const formState = useFormState();
  const fieldLabel = useFieldLabel({
    resource: "ticket-photo-scoutings",
  });
  const ticketTypeId = useMemo(
    () => get(formState.values, "ticket_type_id"),
    [formState.values]
  );
  if (ticketTypeId !== 1) {
    return null;
  }
  return (
    <Fragment>
      <TextInput
        {...props}
        source="ticket_photo_scouting.location"
        maxLength={200}
        validate={required()}
        className={classes.inlineInput}
      />
      <DateInput
        {...props}
        source="ticket_photo_scouting.date"
        validate={required()}
        className={classes.inlineInput}
      />
      <TextInput
        {...props}
        source="ticket_photo_scouting.project_title"
        maxLength={100}
        validate={required()}
        fullWidth
      />
      <TextInput
        {...props}
        source="ticket_photo_scouting.film_director"
        maxLength={100}
        validate={required()}
        className={classes.inlineInput}
      />
      <MovieTypeInput
        {...props}
        source="ticket_photo_scouting.movie_type_id"
        validate={required()}
        className={classes.inlineInput}
      />
      <TextInput
        {...props}
        source="ticket_photo_scouting.motivations"
        maxLength={4000}
        validate={required()}
        multiline
        fullWidth
        rows={5}
      />
      <TextInput
        {...props}
        source="ticket_photo_scouting.synopsis"
        maxLength={4000}
        validate={required()}
        multiline
        fullWidth
      />
      <CompanyInput
        {...props}
        source="ticket_photo_scouting.company"
        fullWidth
      />
      <PhotoScoutingLocationsInput
        {...props}
        label={fieldLabel("ticket_photo_scouting_locations")}
        source="ticket_photo_scouting.ticket_photo_scouting_locations"
        resource="ticket-photo-scouting-locations"
        fullWidth
      />
    </Fragment>
  );
};
export default PhotoScoutingInput;
