import { Button, makeStyles } from "@material-ui/core";

import React from "react";
import { useTranslate } from "react-admin";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 0,
    margin: theme.spacing(2),
    float: "right",
  },
}));

const PasswordResetButton = () => {
  const translate = useTranslate();
  const classes = useStyles();
  return (
    <Button
      className={classes.root}
      type="button"
      color="secondary"
      size="small"
      href="#/password-reset"
    >
      {translate("ra.auth.forgot_password")}
    </Button>
  );
};
export default PasswordResetButton;
