import {
  DateField,
  Pagination,
  ReferenceManyField,
  TextField,
  useTranslate,
} from "react-admin";
import { Divider, Typography } from "@material-ui/core";
import React, { Fragment } from "react";

import NotesField from "./NotesField";
import ResponsiveList from "../../lists/ResponsiveList";
import StateField from "./StateField";
import classnames from "classnames";
import { useListContentStyles } from "../../lists/useStyles";
import useWorkflow from "../useWorkflow";

const PaginationWrapper = ({ fullWidth, addLabel, ...props }) => (
  <Pagination {...props} />
);

const TransactionLogsField = ({ admin = false, ...props }) => {
  const translate = useTranslate();
  const classes = useListContentStyles();
  const { resource } = props;
  const workflow = useWorkflow({ resource });
  if (!workflow) {
    return null;
  }
  return (
    <div className={classnames(classes.root, classes.displayEmptyMessage)}>
      <Typography variant="subtitle1">
        {translate("resources.workflow.fields.logs")}
      </Typography>
      <Divider />
      <ReferenceManyField
        perPage={5}
        pagination={<PaginationWrapper />}
        reference={`workflow/transactions/${resource}`}
        target="id"
        sort={{ field: "WorkflowTransactions.created", order: "desc" }}
        {...props}
      >
        <ResponsiveList
          classes={{ table: classes.table }}
          mobileHeader={
            <Fragment>
              <DateField
                label="resources.workflow.fields.created"
                source="created"
                showTime
              />
              <TextField
                label="resources.workflow.fields.user"
                source="user.name"
              />
            </Fragment>
          }
          mobileBody={
            <Fragment>
              <NotesField
                variant="body2"
                label="resources.workflow.fields.notes"
                source="notes"
              />
              <StateField
                label="resources.workflow.fields.state"
                sortBy="WorkflowTransactions.state"
              />
            </Fragment>
          }
        >
          <TextField label="resources.workflow.fields.id" source="id" />
          <DateField
            label="resources.workflow.fields.created"
            source="created"
            showTime
            sortBy="WorkflowTransactions.created"
          />
          <TextField
            label="resources.workflow.fields.user"
            source="user.name"
            sortBy="Users.username"
          />
          <NotesField
            label="resources.workflow.fields.notes"
            variant="body2"
            source="notes"
            admin={admin}
            sortable={false}
          />

          <StateField
            label="resources.workflow.fields.state"
            sortBy="WorkflowTransactions.state"
          />
        </ResponsiveList>
      </ReferenceManyField>
    </div>
  );
};

export default TransactionLogsField;
