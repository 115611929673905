import { API_URL } from "../../config";
import { Button } from "@material-ui/core";
import PrintIcon from "@material-ui/icons/Print";
import React from "react";
import { getToken } from "../../authHeaders";
import { useFormState } from "react-final-form";
import { useTranslate } from "ra-core";

const PrintButton = ({ useFormData = false, ...props }) => {
  const translate = useTranslate();
  const formState = useFormState();
  const handleClick = () => {
    const data = btoa(JSON.stringify(formState.values));
    const url = `${API_URL}/tickets/print?data=${data}&token=${getToken()}`;
    window.open(url, "_blank");
  };
  return (
    <Button startIcon={<PrintIcon />} onClick={handleClick}>
      {translate("resources.tickets.buttons.print")}
    </Button>
  );
};

export default PrintButton;
