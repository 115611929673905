import { Alert, AlertTitle } from "@material-ui/lab";
import { ArrayInput, SimpleFormIterator } from "ra-ui-materialui";
import { required, useTranslate } from "ra-core";

import AddressInput from "../../../../companies/inputs/AddressInput";
import { Fragment } from "react";
import React from "react";
import TextInput from "../../../inputs/TextInput";
import { Typography } from "@material-ui/core";
import { get } from "lodash";
import { makeStyles } from "@material-ui/core";
import useFieldLabel from "../../../useFieldLabel";

const useStyles = makeStyles((theme) => ({
  root: {
    "& label[for=companies] span": {
      fontSize: 20,
    },
    "& li>p": {
      display: "none",
      visibility: "hidden",
    },
  },
}));
const InputWrapper = ({ Component, ...props }) => {
  const readonly = get(props, "record.readonly", false);
  return <Component {...props} disabled={readonly} />;
};
const TitleField = ({ record }) => {
  const translate = useTranslate();
  const role = get(record, "role.name");
  const company = get(record, "company.name");
  const readonly = get(record, "readonly");
  return (
    <Fragment>
      <Typography variant="h6" style={{ marginTop: 8 }}>
        {translate("resources.users.profile.company_title", { role, company })}
      </Typography>
      {readonly && (
        <Alert severity="info">
          <AlertTitle>
            <strong>
              {translate("resources.users.profile.company_readonly")}
            </strong>
          </AlertTitle>
          {translate("resources.users.profile.company_readonly_message")}
        </Alert>
      )}
    </Fragment>
  );
};
const CompaniesInput = (props) => {
  const classes = useStyles();
  const fieldLabel = useFieldLabel({ resource: props.source });
  return (
    <ArrayInput {...props} classes={classes}>
      <SimpleFormIterator disableAdd disableRemove classes={classes}>
        <TitleField />
        <InputWrapper
          Component={TextInput}
          source="company.name"
          maxLength={100}
          fullWidth
          validate={required()}
          label={fieldLabel("name")}
        />
        <InputWrapper
          Component={TextInput}
          source="company.country"
          maxLength={100}
          validate={required()}
          label={fieldLabel("country")}
        />
        <InputWrapper
          Component={TextInput}
          source="company.vat_code"
          maxLength={20}
          validate={required()}
          label={fieldLabel("vat_code")}
        />
        <InputWrapper
          Component={AddressInput}
          label={fieldLabel("legal_address")}
          source="company.legal_address"
          fullWidth
          required
        />
        <InputWrapper
          Component={AddressInput}
          label={fieldLabel("operative_address")}
          source="company.operative_address"
          fullWidth
        />
      </SimpleFormIterator>
    </ArrayInput>
  );
};
export default CompaniesInput;
