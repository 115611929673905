import { useMutation, useNotify, useRedirect, useRefresh } from "react-admin";

import { get } from "lodash";
import { useCallback } from "react";

const mapFieldErrors = (field, errors) => {
  const keys = Object.keys(errors);
  const messages = keys.filter((k) => typeof errors[k] === "string");

  if (messages.length > 0) {
    return {
      [field]: messages.map((m) => errors[m]).join("\n"),
    };
  } else {
    const out = keys.reduce(
      (errorMap, key) => ({
        ...errorMap,
        ...mapFieldErrors(key, errors[key]),
      }),
      {}
    );
    return {
      [field]: out,
    };
  }
};

let cakephpErrorMapper = (errors) => {
  var fields = Object.keys(errors);
  var validationErrors = fields.reduce((errorsMap, field) => {
    return {
      ...errorsMap,
      ...mapFieldErrors(field, errors[field]),
    };
  }, {});
  return validationErrors;
};
const useValidatableSave = ({
  type = null,
  resource,
  onSuccess = undefined,
  ...props
}) => {
  const [mutate] = useMutation();
  const redirect = useRedirect();
  const refresh = useRefresh();
  const notify = useNotify();
  const save = useCallback(
    async (values) => {
      let response;
      try {
        response = await mutate(
          {
            type:
              type === null
                ? values.id && values.id > 0
                  ? "update"
                  : "create"
                : type,
            resource,
            payload: { id: values.id, data: values },
          },
          { returnPromise: true }
        );
      } catch (error) {
        const errors = get(error, "body.data.errors", null);
        const message = get(error, "body.data.message", null);
        if (message) {
          notify(message, "error");
        }

        if (errors) {
          const mappedErrors = cakephpErrorMapper(errors);
          return mappedErrors;
        } else {
          return false;
        }
      }

      if (!onSuccess) {
        if (props.refresh === true) {
          refresh();
        } else {
          if (props.redirect !== undefined) {
            redirect(props.redirect);
          } else {
            redirect("list", props.basePath);
          }
        }
        notify(
          "ra.notification." + (values.id > 0 ? "updated" : "created"),
          "info",
          {
            smart_count: 1,
          }
        );
      } else onSuccess(response, values);
    },
    [
      mutate,
      type,
      resource,
      props.redirect,
      props.refresh,
      redirect,
      refresh,
      notify,
      props.basePath,
      onSuccess,
    ]
  );
  return save;
};

export default useValidatableSave;
