import {
  BooleanInput,
  DateInput,
  SimpleForm,
  TextInput,
  maxLength,
  minLength,
  required,
} from "react-admin";

import CompaniesInput from "./inputs/CompaniesInput";
import PasswordInput from "./inputs/PasswordInput";
import React from "react";
import RolesInput from "./inputs/RolesInput";
import StatusInput from "./inputs/StatusInput";
import useValidatableSave from "../components/forms/useValidatableSave";

const Form = ({ create = false, permissions, ...props }) => {
  const save = useValidatableSave({ ...props, redirect: undefined });
  return (
    <SimpleForm {...props} save={save}>
      <StatusInput source="status" validate={required()} />
      <TextInput source="username" validate={required()} />
      <PasswordInput permissions={permissions} create={create} />
      <TextInput source="email" validate={required()} />
      <TextInput source="profile.name" validate={required()} />
      <TextInput source="profile.surname" validate={required()} />
      <TextInput
        source="profile.fiscal_code"
        validate={[required(), minLength(16), maxLength(16)]}
      />
      <TextInput source="profile.birth_place" />
      <DateInput source="profile.birth_date" />
      <TextInput source="profile.phone" />
      <BooleanInput source="allow_local_login" />
      <RolesInput source="roles" validate={required()} />
      <CompaniesInput source="companies" fullWidth />
    </SimpleForm>
  );
};
export default Form;
