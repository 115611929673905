import Create from "./Create";
import Edit from "./Edit";
import List from "./List";
import { UserDelegateIcon } from "../icons";

export default {
  icon: UserDelegateIcon,
  list: List,
  edit: Edit,
  create: Create,
  options: {
    group: "dashboard",
    roles: ["legal-representative", "admin", "user"],
  },
};
