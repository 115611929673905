import { SimpleForm, TextField, required, usePermissions } from "react-admin";

import CompanyInput from "../users/inputs/CompanyInput";
import FirstInterviewInput from "./inputs/FirstInterviewInput";
import LocationScoutingInput from "./inputs/LocationScoutingInput";
import MediaInput from "./inputs/MediaInput";
import NotesInput from "../components/workflow/inputs/NotesInput";
import NotesPrivateInput from "../components/workflow/inputs/NotesPrivateInput";
import PhotoScoutingInput from "./inputs/PhotoScoutingInput";
import PrintButton from "./buttons/PrintButton";
import React from "react";
import StateInfoField from "../components/workflow/fields/StateInfoField";
import TicketTypeInput from "./inputs/TicketTypeInput";
import Toolbar from "../components/workflow/form/Toolbar";
import TransactionLogsField from "../components/workflow/fields/TransactionLogsField";
import UserInput from "../components/inputs/UserInput";
import useValidatableSave from "../components/forms/useValidatableSave";

const Form = ({ create = false, ...props }) => {
  const save = useValidatableSave({ ...props, redirect: undefined });
  const { loaded, permissions } = usePermissions();
  const isAdmin = loaded && permissions(["admin"]);
  return (
    <SimpleForm
      {...props}
      toolbar={
        <Toolbar>
          <PrintButton useFormData />
        </Toolbar>
      }
      save={save}
      initialValues={{ is_private: false }}
    >
      {!create && <TextField source="protocol" variant="h5" />}
      {isAdmin && (
        <UserInput source="user_id" validate={required()} disabled={!isAdmin} />
      )}
      <CompanyInput autocomplete source="company_id" />
      <TicketTypeInput source="ticket_type_id" validate={required()} />
      <PhotoScoutingInput fullWidth />
      <LocationScoutingInput fullWidth />
      <FirstInterviewInput fullWidth />
      <MediaInput source="media" />
      <TransactionLogsField admin={isAdmin} fullWidth />
      <NotesInput source="notes" fullWidth />
      <NotesPrivateInput admin={isAdmin} source="is_private" fullWidth />
      <StateInfoField />
    </SimpleForm>
  );
};
export default Form;
