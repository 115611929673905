import List from "./List";
import { TicketCalendarIcon } from "../icons";

export default {
  options: {
    group: "dashboard",
    roles: ["admin"],
  },
  icon: TicketCalendarIcon,
  list: List,
};
