import {
  Button,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  makeStyles,
} from "@material-ui/core";
import { useForm, useFormState } from "react-final-form";

import React from "react";
import { get } from "lodash";
import { useTranslate } from "ra-core";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0.5),
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));
const SignupStepper = ({ children, ...props }) => {
  const formState = useFormState();
  const form = useForm();
  const [activeStep, setActiveStep] = React.useState(0);
  const handleNext = () => {
    form.batch(() => {
      const fields = form
        .getRegisteredFields()
        .filter((field) => !field.startsWith("company"));
      fields.forEach((field) => form.blur(field));
      const stepErrors = fields
        .map((field) => get(formState.errors, `${field}`, undefined))
        .filter((fieldError) => fieldError !== undefined);
      if (stepErrors.length === 0) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const translate = useTranslate();
  const classes = useStyles();

  return (
    <Stepper
      className={classes.root}
      activeStep={activeStep}
      orientation="vertical"
    >
      {React.Children.map(children, (field, index) => (
        <Step key={index}>
          <StepLabel>
            {translate(
              `resources.users.step.${field.props.source || "account"}`
            )}
          </StepLabel>
          <StepContent TransitionProps={{ unmountOnExit: false }}>
            {React.cloneElement(field, { ...props })}
            <div className={classes.actionsContainer}>
              <div>
                {activeStep < children.length - 1 ? (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleNext}
                    className={classes.button}
                  >
                    {translate("ra.action.next")} &rarr;
                  </Button>
                ) : (
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.button}
                  >
                    &larr; {translate("ra.action.back")}
                  </Button>
                )}
              </div>
            </div>
          </StepContent>
        </Step>
      ))}
    </Stepper>
  );
};

export default SignupStepper;
