import React, { Fragment } from "react";

import { DateInput } from "ra-ui-materialui";
import TextInput from "../../../inputs/TextInput";
import { required } from "ra-core";

const AccountStepInput = ({ record, ...props }) => (
  <Fragment>
    <TextInput
      {...props}
      source="profile.name"
      validate={required()}
      fullWidth
      maxLength={50}
      disabled
    />
    <TextInput
      {...props}
      source="profile.surname"
      validate={required()}
      fullWidth
      maxLength={50}
      disabled
    />
    <TextInput
      {...props}
      source="profile.fiscal_code"
      validate={required()}
      fullWidth
      maxLength={16}
      disabled
    />
    <TextInput
      {...props}
      source="email"
      maxLength={255}
      validate={required()}
      fullWidth
    />
    <TextInput
      {...props}
      source="profile.birth_place"
      validate={required()}
      fullWidth
      maxLength={50}
    />
    <DateInput
      {...props}
      source="profile.birth_date"
      validate={required()}
      fullWidth
    />
    <TextInput source="profile.phone" maxLength={30} {...props} />
  </Fragment>
);
export default AccountStepInput;
