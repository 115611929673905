import { CheckboxGroupInput } from "ra-ui-materialui";
import React from "react";

const PERMISSIONS = [
  "tickets/photo_scouting",
  "tickets/location_scouting",
  "tickets/first_interview",
].map((id) => ({
  id,
  name: `resources.user-delegates.perms.${id}`,
}));
export const formatMany = (many) => {
  let array = many ? many.map((p) => p.permission) : [];
  return array;
};

export const parseMany = (many) => {
  let objects = many ? many.map((permission) => ({ permission })) : [];
  return objects;
};

const PermsInput = (props) => {
  return (
    <CheckboxGroupInput
      {...props}
      choices={PERMISSIONS}
      parse={parseMany}
      format={formatMany}
    />
  );
};
export default PermsInput;
