import { APP_URL } from "../../../../config";

const load = ({ search }) =>
  fetch(`${APP_URL}spid/load${search}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .catch((error) => error);

export default load;
